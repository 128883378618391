import React from "react";

const ws = {
  color: "whitesmoke",
  fontSize: 15,
};

export default function Div3Sidebar({ bg, handleClose }) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: bg ? bg : null,
      }}
    >
      <p style={{ color: "white", fontWeight: "bold" }}>What should I write?</p>
      <div style={{ width: "96%" }}>
        <p style={ws}>
          Simply provide an honest account of what you observed and experienced
          during the COVID-19 pandemic in Australia. Our AI program, Winnie,
          will transform your testimony into a statutory declaration draft that
          you can later edit.
        </p>
        <p style={ws}>
          Don't worry too much about your grammar and terminology. Winnie is
          designed to re-write your testimony in a way that meets legal
          standards.
        </p>
        <p style={ws}>
          If in doubt, just write as though you're telling your story to a
          journalist.
        </p>
        <h4 style={{ color: "lightgreen" }}>Things you could write about:</h4>
        <ul>
          <li style={ws}>
            Vaccine injuries (confirmed or suspected) that you experienced
          </li>
          <li style={ws}>
            Vaccine injuries (confirmed or suspected) that you observed in
            others
          </li>
          <li style={ws}>Job loss due to government policies</li>
          <li style={ws}>
            Business closure or downturn due to government policies
          </li>
          <li style={ws}>
            Feelings of coercion, i.e. financial pressure to get vaccinated
          </li>
          <li style={ws}>
            Perceived abuses of power, i.e. getting fined or arrested for
            peacefully protesting
          </li>
          <li style={ws}>
            Anything else that you believe constitutes negligence, corruption,
            abuse of power and/or a violation of human rights
          </li>
        </ul>
        <h4 style={{ color: "#F08080" }}>
          Things you should consider excluding:
        </h4>
        <ul>
          <li style={ws}>Rumours and hearsay</li>
          <li style={ws}>Speculation about the motives of policymakers</li>
          <li style={ws}>Speculation about what might happen in the future</li>
          <li style={ws}>Criticism of policies unrelated to COVID-19</li>
          <li style={ws}>
            Criticism of policies and individuals who do not fall within
            Australia's jurisdiction
          </li>
        </ul>
        <h4 style={{ color: "gold" }}>General advice:</h4>
        <ul>
          <li style={ws}>
            <span style={{ fontWeight: 600, color: "gold" }}>
              More detail is better than less.
            </span>{" "}
            If you were admitted to hospital, what was the name of the hospital?
            If your business suffered during the lockdowns, why did it suffer?
            If you were harmed by a vaccine, which vaccine was it?
          </li>
          <li style={ws}>
            <span style={{ fontWeight: 600, color: "gold" }}>
              Express yourself openly.
            </span>{" "}
            The AI is designed to handle strong language, within reason. It will
            re-write your testimony in a way that conveys your sentiment whilst
            also complying with legal standards.
          </li>
          <li style={ws}>
            <span style={{ fontWeight: 600, color: "gold" }}>
              Subjectivity is acceptable.
            </span>{" "}
            Not all forms of harm are measurable. Feel free to elaborate on the
            emotional and psychological impact of the events that you
            experienced.
          </li>
          <li style={ws}>
            <span style={{ fontWeight: 600, color: "gold" }}>
              Differentiate FACTS and BELIEFS.
            </span>{" "}
            A fact is something that you know to be true (i.e. "I experienced
            heart palpitations after receiving the COVID-19 vaccine"). A belief
            is something that you suspect is true, but cannot prove (i.e. "I
            believe the heart palpitations were caused by the vaccine").{" "}
            <span style={{ fontWeight: 600 }}>
              When in doubt, phrase things as a statement of belief.
            </span>
          </li>
        </ul>
        <p style={ws}>
          <span style={{ color: "gold", fontWeight: 600 }}>REMEMBER:</span> You
          can't get in trouble for stating something that you sincerely believe
          to be true. Personal opinions are allowed, as long as they are not
          misrepresented as facts.
        </p>
        <p style={ws}>
          Finally,{" "}
          <span style={{ color: "#F08080", fontWeight: 600 }}>
            DO NOT WRITE ANYTHING THAT IS KNOWINGLY FALSE OR DECEPTIVE.
          </span>{" "}
          Lying on a statutory declaration is a criminal offence. We do not
          condone anyone who uses this tool in a way that is deliberately
          misleading.
        </p>
        <hr></hr>
        {bg ? (
          <button style={{ marginBottom: "10px" }} onClick={handleClose}>
            CLOSE
          </button>
        ) : null}
      </div>
    </div>
  );
}
